.gm-style h1, .gm-style p {
    font-family: Roboto;
}

.gm-style .gm-style-iw-d {
    overflow: hidden !important;
}

.gm-style .gm-style-iw-c {
    padding: 0px !important;
}

.gm-style .gm-style-iw-c button { display: none !important; }
.gm-style div:focus { outline: none !important; }
